/* Global styles */
.WeAreWelleo {
    min-height: 100vh;
}

p {
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    color: white;
}

h1, h2, h3, .Values {
    background: linear-gradient(to right, #FFD72B, #FF8229);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-style: italic;
}

/* Container styles */
.WRW-Container {
    display: grid;
    grid-template-areas:
        'top top top top top top'
        'title1 title1 space space space space'
        'header header main main right right'
        'left left mid mid footer footer'
        'title2 title2 space2 space2 space2 space2'
        'team team team team team team';
    gap: 10px;
    border-radius: 8px;
    margin-bottom: 100px;
    position: relative;
    z-index: 1;
    width: 100%;
}

.WRW-Team {
    background-color: #1F1E1F;
    padding: 5px;
    margin: 0 10px;
    border-radius: 8px;
    display: flex;
}
/* Item styles */
.WRW-Item {
    background-color: #1F1E1F;
    padding: 5px;
    margin: 0 10px 20px; /* Adjusted the bottom margin to add space between items */
    border-radius: 8px;
    display: flex;
}



    .WRW-Item h4 {
        margin-bottom: 0;
    }

    .WRW-Item p {
        top: calc(100% + 10px);
        width: 100%;
    }

.WRW-Team {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px; /* Adjust the gap between images as needed /
    padding: 20px; / Add padding around the grid if needed */
}

.team-image {
    width: 75%;
    height: auto;
    object-fit: cover; /* Ensure images cover their container */
    border-radius: 5px; /* Optional: Add border radius to images */
    padding: 0px;
}


.WRW-Item1, .WRW-Item2, .WRW-Item3, .WRW-Item4, .WRW-Item5, .WRW-Item6 {
    background-color: #1F1E1F;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    text-align: right;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.WRW-Item-Item1 {
    width: 95%;
    padding-left: 25px;
}

.WRW-Item-Item2 {
    width: 750px;
    padding: 25px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.BolderP {
    font-size: 20px;
    font-style: normal;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    color: white;
}

.BolderH3 {
    font-size: 32px;
}
.WRW-Img1 {
    width: 75%;
    border-radius: 4px;
    max-width: 100%;
    height: auto;
}

.WRW-Img {
    width: 50px;
    max-width: 100%;
    height: auto;
}

.WRW-Item {
    grid-area: top;
    background-color: #1F1E1F;
    border-radius: 8px;
    text-align: left;
    display: flex;
    justify-content: space-between;
}
.WRW-Title1 {
    grid-area: title1;
    margin: -15px;
    margin-left: 30px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
}
.WRW-Title2 {
    grid-area: title2;
    margin: -15px;
    margin-left: 30px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
}

.OurValues,
.OurTeam {
    background: linear-gradient(to right, #FFD72B, #FF8229);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 30px;
}

.WRW-Item1 {
    grid-area: header;
}
.WRW-Item2 {
    grid-area: main;
}
.WRW-Item3 {
    grid-area: right;
}
.WRW-Item4 {
    grid-area: left;
}
.WRW-Item5 {
    grid-area: mid;
}
.WRW-Item6 {
    grid-area: footer;
}
.WRW-Item6 {
    grid-area: footer;
}
.WRW-Item7{
    grid-area: team;
}
.WRW-Img7 {
    width: 100%;
}
@media screen and (max-width: 767px) {
    .WRW-Container {
        grid-template-areas:
            'top top top top'
            'title1 title1 title1 title1'
            'header header header header'
            'main main main main'
            'right right right right'
            'left left left left'
            'mid mid mid mid'
            'footer footer footer footer'
            'title2 title2 title2 title2'
            'team team team team ';
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(auto, auto);
    }

    .WRW-Item-Item1 {
        width: 95%;
        margin-bottom: 25px;
        padding: 0;
        text-align: center;
        align-items: flex-start;
    }
    .BolderH3 {
        
    }
    .BolderP {
    }
    .WRW-Item-Item2 {
        width: 100%;
        justify-content: center;
    }

    .WRW-Team {
        grid-template-columns: repeat(2, 1fr);
    }

    .WRW-Item p {
        align-items: center; /* Center items horizontally */
        text-align: center; /* Center text inside */
        text-align: center;
    }
    .WRW-Title1 {
        align-items: center; /* Center items horizontally */
        justify-content: center;
        text-align: center; /* Center text inside */
        padding: 0px;
        margin: 0px;
    }
    .WRW-Title2 {
        align-items: center; /* Center items horizontally */
        justify-content: center;
        text-align: center; /* Center text inside */
        padding: 0px;
        margin: 0px;
    }
    .WRW-Item {
        flex-direction: column;
        align-items: center; /* Center items horizontally */
        justify-content: center;
        text-align: center; /* Center text inside */
        margin-bottom: 40px; /* Adjust spacing between items */
    }
}
    /* Responsive styles */
    @media screen and (min-width: 768px) and (max-width: 999px) {
        .WRW-Container {
            display: grid;
            width: 95%;
            grid-template-areas:
                'top top top top top top'
                'title1 title1 title2 title2 title2 title2'
                'header header main main right right'
                'left left mid mid footer footer'
                'title2 title2 title2 title2'
                'team team team team ';
            gap: 10px;
            border-radius: 8px;
            margin: auto;
            margin-top: 10px;
            margin-bottom: 100px;
            position: relative;
            z-index: 1;
            grid-template-columns: repeat(6, 1fr);
        }


        .WRW-Item {
            align-items: center; /* Center items horizontally */
            text-align: center; /* Center text inside */
        }

        .WRW-Item-Item1 {
            width: 100%;
            margin-bottom: 25px;
            padding-left: 10px;
            text-align: center;
            align-items: flex-start;
        }

        .WRW-Item-Item2 {
            width: 100%;
            justify-content: center;
        }

        .WRW-Team {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media screen and (max-width: 1999px) and (min-width: 1000px) {
        .WRW-Container {
            display: grid;
            width: 80%;
            grid-template-areas:
                'top top top top top top'
                'title1 title1 title2 title2 title2 title2'
                'header header main main right right'
                'left left mid mid footer footer'
                'title2 title2 title2 title2'
                'team team team team ';
            gap: 10px;
            border-radius: 8px;
            margin: auto;
            margin-top: 10px;
            margin-bottom: 100px;
            position: relative;
            z-index: 1;
            grid-template-columns: repeat(6, 1fr);
        }
    }

    @media screen and (min-width: 2000px) and (max-width: 3000px) {
        .WRW-Container {
            display: grid;
            width: 80%;
            height: 100%;
            grid-template-areas:
                'top top top top top top'
                'title1 title1 title2 title2 title2 title2'
                'header header main main right right'
                'left left mid mid footer footer'
                'title2 title2 title2 title2';
            gap: 10px;
            border-radius: 8px;
            margin: auto; /* Center horizontally */
            margin-top: 10px; /* Adjust as needed */
            margin-bottom: 100px;
            position: relative;
            z-index: 1;
            grid-template-columns: repeat(6, 1fr);
        }

        .WRW-Item {
            margin-top: 50px;
        }

        .WRW-Item-Item1 {
        }

        .WRW-Img1 {
            width: 450px;
        }

        .WRW-Item1, .WRW-Item2, .WRW-Item3, .WRW-Item4, .WRW-Item5, .WRW-Item6 {
            height: 300px;
            padding: 20px;
        }

            .WRW-Item1 h4, .WRW-Item2 h4, .WRW-Item3 h4, .WRW-Item4 h4, .WRW-Item5 h4, .WRW-Item6 h4 {
                font-size: 24px;
            }

            .WRW-Item1 p, .WRW-Item2 p, .WRW-Item3 p, .WRW-Item4 p, .WRW-Item5 p, .WRW-Item6 p {
                font-size: 20px;
            }

        .BolderP {
            font-size: 24px;
            width: 80%;
        }

        .BolderH3 {
            font-size: 46px;
        }
    }
