@font-face {
    font-family: 'Montserrat-BoldItalic';
    src: url('../fonts/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-SemiBold';
    src: url('../fonts/Nunito-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

.ResponseMessage {
    display: flex;
    justify-content: left;
    align-items: center;
    text-align: center;
    width: 100%;
}

.message {
    font-size: 14px;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    text-align: left;
}

    .message.success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
        width: 63%;
        margin-top: 15px;
    }

    .message.error {
        color: #721c24;
        margin-top: 15px;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        width: 63%;
    }
.HomePage3 {
    height: 100%;
}
.SignUpBody {
    display: flex;
    margin: 0;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    position: relative;
    z-index: 3; 
    left: 0;
    right: 0;
}

.Image {
    width: 60%;
}

.H12 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-style: italic;
    font-size: 42px;
    background: linear-gradient(to right, #FFD72B, #FF8229);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    width: 100%;
}

.Content1 {
    width: 50%;
}

.Content2 {
    text-align: right;
    width: 50%;
}

.CheckBoxDiv {
    width: 500px;
}

.InputButton {
    flex-direction: column;
    z-index: 4;
}

.Input-Body2 {
    color: #ffffff;
    font-size: 16px;
    padding: 15px 25px;
    background: #2A282A;
    border: none;
    border-radius: 10px;
    width: 60%;
    margin-bottom: 10px;
}

.HomeSignUpButton {
    color: #282c34;
    background: linear-gradient(to right, #FFD72B, #FF8229);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    width: 500px;
    font-family: "Montserrat-SemiBold";
    padding: 15px 25px;
}

.buttonSign {
    margin-top: 15px;
    /*    width: 66%; */
}
.overlay{
}
label {
    color: white;
    font-family: "Nunito-SemiBold";
    font-size: 16px;
}

.2P {
    width: 100%;
}

#checkbox {
    margin-bottom: 10px;
}

.NyhetGDPR {
    text-decoration: none;
    font-family: "Nunito-SemiBold";
}

    .NyhetGDPR:visited {
        text-decoration: none;
        font-family: "Nunito-SemiBold";
    }

.colored-text, .white-text {
    font-family: "Nunito-SemiBold";
    font-size: 16px;
}

.colored-text {
    background: linear-gradient(to right, #FFD72B, #FF8229);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.readmore {
    text-decoration: none;
    color: inherit;
}

    .readmore:link, .readmore:visited, .readmore:hover, .readmore:active {
        color: inherit;
        text-decoration: none;
    }

.white-text {
    color: white;
}
.BolderP {
    font-size: 20px;
    font-style: normal;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    color: white;
}


@media (max-width: 767px) {
    .SignUpBody {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
    }

   

    .CheckBoxDiv {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
    }

    .Content1, .Content2 {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 95%;
    }

    .checkboxText {
        font-size: 12px;
    }

    .Image {
        width: 50%;
        margin-top: 20px;
    }

    .H12 {
        font-size: 20px;
    }

    .SignUpP {
        font-size: 12px;
    }

    .Input-Body2, .HomeSignUpButton {
        width: 80%;
        font-size: 14px;
        padding: 10px 20px;
    }

    #checkbox {
        transform: scale(0.8);
    }
    .ResponseMessage {
        justify-content: center;
    }
    .message {
        text-align: center;
    }
    
    .BolderP {
        font-size: 14px;
    }
}


@media (min-width: 768px) and (max-width: 1200px) {
    .SignUpBody {
        width: 100%;
        margin: 20px auto;
    }

    .BolderP{
        font-size: 16px;
    }

    .Content1, .Content2 {
        width: 100%;
    }

    .Content1 {
        margin-left: 40px;
    }

    .Content2 {
        margin-right: 75px;
    }

    .Image {
        width: 70%;
        margin-top: 20px;
    }

    .H12 {
        font-size: 20px;
        width: 275px;
    }

    .Input-Body2 {
        width: 275px;
    }

    .Image {
        width: 225px;
    }
}
@media (min-width: 1201px) and (max-width: 1999px) {
    .SignUpBody {
        width: 100%;
    }

    .Content1, .Content2 {
        width: 100%;
    }

    .Content1 {
        margin-left: 200px;
        margin-top: -75px;
    }

    .Content2 {
        margin-right: 200px;
    }

    .Image {
        width: 375px;
    }

    .Input-Body2 {
        width: 500px;
        font-size: 17px;
    }

    .checkboxText {
        font-size: 17px;
    }

    .CheckBoxDiv {
        width: 500px;
    }

    .H12 {
        font-size: 32px;
        width: 500px;
    }

    .SignUpP {
        font-size: 17px;
    }
    

    .colored-text {
        font-size: 17px;
    }
}
@media screen and (min-width: 2000px) and (max-width: 3000px) {

    .checkboxText {
        font-size: 20px;
    }

    .H12 {
        font-size: 35px;
    }

    .SignUpP {
        font-size: 20px;
    }
    .Image {
        width: 400px;
    }
    .Content1 {
        margin-left: 350px;
        margin-top: -100px;
    }

    .Content2 {
       
    }
}
