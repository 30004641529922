@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

@font-face {
    font-family: 'Montserrat-BoldItalic';
    src: url('../fonts/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-SemiBold';
    src: url('../fonts/Nunito-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

.footer2 {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    box-sizing: border-box;
    border: none;
    z-index: 1000;
}

.footer-content2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 1000;
}

.footer-left {
    color: white;
    display: flex;
    align-items: center;
    text-align: left;
    font-family: "Montserrat-SemiBold";
    font-size: 20px;
    flex-grow: 1;
}

.footer-right {
    color: rgba(255, 255, 255, 0.4);
    text-align: right;
    font-size: 12px;
    font-family: "Nunito-SemiBold";
}

.Icons {
    display: flex;
    flex-direction: row;
    max-width: 100%; 
    justify-content: flex-start; 
    flex-wrap: nowrap; 
}

.logos {
    color: white;
    font-size: 20px;
    border-radius: 15px;
    padding: 0;
    margin: 0 3px; 
    width: 24px;  
}

.FooterLinks {
    padding: 0;
    margin: 0 5px; /* Reduce margin between links */
}

/* For smaller devices, up to 767px */
@media (max-width: 767px) {
    .footer2 {
        position: relative;
    }

    .footer-right {
        font-size: 10px;
    }

    .footer-left {
        font-size: 10px;
    }

    .logos {
        width: 16px; /* Adjust the size directly instead of font-size */
        height: 16px; /* Keep proportions */
    }

    .Icons {
        display: flex;
        flex-direction: row;
        width: auto; /* Let content adjust based on logos' size */
    }
}

/* For devices between 768px and 1440px */
@media (min-width: 768px) and (max-width: 1440px) {
    .footer2 {
        position: relative;
    }

    .footer-right {
        font-size: 10px;
    }

    .footer-left {
        font-size: 10px;
    }

    .logos {
        width: 24px; /* Increase size for medium screens */
        height: 24px;
    }

    .Icons {
        display: flex;
        flex-direction: row;
        width: auto; /* Use auto to prevent conflict with logo size */
    }
}

/* For larger devices between 1440px and 1920px */
@media (min-width: 1440px) and (max-width: 1920px) {
    .footer2 {
        position: relative;
    }

    .footer-right {
        font-size: 12px;
    }

    .footer-left {
        font-size: 16px;
    }

    .logos {
        width: 24px; /* Larger size for bigger screens */
        height: 24px;
    }

    .Icons {
        display: flex;
        flex-direction: row;
        width: auto; /* Allow content to scale naturally */
    }
}

/* For extra large devices between 1921px and 2600px */
@media (min-width: 1921px) and (max-width: 2600px) {
    .footer2 {
        position: relative;
    }

    .footer-right {
        font-size: 20px;
    }

    .footer-left {
        font-size: 20px;
    }

    .logos {
        width: 32px; /* Scale icons further for very large screens */
        height: 32px;
    }

    .Icons {
        display: flex;
        flex-direction: row;
        width: auto; /* Let the logos' size define the container's width */
    }
}


