
@font-face {
    font-family: 'Montserrat-BoldItalic';
    src: url('../fonts/Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600; /* Semi-bold weight */
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-SemiBold';
    src: url('../fonts/Nunito-SemiBold.ttf') format('truetype');
    font-weight: 600; /* Semi-bold weight */
    font-style: normal;
}
.WorkAtWelleo {
   background-color: transparent;
   z-index: 2;
   min-height: 100vh;
}
h1,
h2,
h3,
h4,
h5 {
    font-family: "Montserrat-BoldItalic", sans-serif;
    font-weight: 700;
    font-style: italic;
}
h4 {
    color: white;
}
h5 {
    color: white; 
}
p {
    font-family: "Nunito-SemiBold", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    color: white;
}
.overlay3 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: linear-gradient( to top right, rgba(255, 130, 41, 0.5) 0%, /* Adjust transparency here */
    rgba(255, 215, 43, 0.2) 25%,
    transparent 50%,
    rgba(255, 215, 43, 0.2) 75%, 
    rgba(255, 130, 41, 0.1) 100% 
    );
}
.ReadMoreButton {
    grid-area: bottomR;
    padding: 0px 15px 0px 15px;
    color: #282c34;
    padding: 15px 25px 15px 25px;
    background: linear-gradient(to right, #FFD72B, #FF8229);
    border: 0px;
    border-radius: 10px;
    font-family: "Montserrat-BoldItalic";
    font-size: 16px;
}
.ApplHereButton {
    grid-area: bottomL;
    padding: 0px 15px 0px 15px;
    color: #282c34;
    padding: 15px 25px 15px 25px;
    background: linear-gradient(to right, #FFD72B, #FF8229);
    border: 0px;
    border-radius: 10px;
    font-family: "Montserrat-BoldItalic";
    font-size: 16px;
}
.WAW_P {
    font-family: "Nunito-SemiBold", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    color: white;
}

.WAW-grid-container1,
.WAW-grid-container2,
.WAW-grid-container2-item1,
.WAW-grid-container2-item2,
.WAW-grid-container2-item2-Internship,
.WAW-grid-container2-item3,
.WAW-grid-container2-item4 {
    position: relative;
    z-index: 3;
}


.TWA, .WAW, .WRW {
    color: #A6A0A6;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    font-family: 'Montserrat-SemiBold';
    font-size: 12px;
    padding: 12px;
}
    .TWA:hover, .WAW:hover, .WRW:hover {
        color: white;
    }

    .TWA:active, .WAW:active, .WRW:active {
        color: white; 
    }


.WorkAtWelleoBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    padding-bottom: 150px;
}
.SecondContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates two columns of equal width */
    justify-items: center; /* Centers items horizontally within their cells */
    align-items: center; /* Centers items vertically within their cells */
    gap: 10px; /* Sets both row-gap and column-gap to 10px */
    z-index: 2;
    justify-content: center; /* Centers the entire grid within the container if there's extra space */
}
.OpenPositionsDiv {
    text-align: left;
    z-index: 2;
    left: 0;
}
.FirstContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Creates two columns of equal width */
    justify-items: center; /* Centers items horizontally within their cells */
    align-items: center; 
    z-index: 2;
    justify-content: center; 
}

.WAW-grid-container2-item2 {
    grid-area: main;
    background-color: #2A282A;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.WAW-grid-container2-item2 {
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 100px; 
}

    .WAW-grid-container2-item2 img {
        max-width: calc(100% - 40px);
        max-height: 100%; 
        object-fit: contain; 
    }

.WorkLoadImages {
    max-width: 100%;
    height: auto;
}
.got_30_50 { /*
    background-image: url('./images/got_30-50.png');
    height: 250px;
    z-index: 4;*/
}

.WAW-grid-item {
    display: flex;
    flex-direction: row;
}

.WAW-grid-item-text {
    flex: 1; 
    margin: 0px 0px 0px 0px;
    text-align: left; 
    color: white; 
}

.WAW-grid-item-image {
    text-align: right;
}
.JoinUs {
    width: 500px;
    max-width: 100%;
    height: auto;
}
.BolderP {
    font-size: 20px;
    font-style: normal;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    color: white;
}
.BolderH3 {
    font-size: 32px;
}

.OpenPositions {
    background: linear-gradient(to right, #FFD72B, #FF8229);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 30px;
    text-align: left;
    left: 0;
}
.WAW-grid-container1 {
    width: 100%;
    background-color: #1F1E1F;
    border-radius: 8px;
}

.WAW-grid-container2 {
    display: grid;
    min-height: 500px;
    max-height: 500px;
    width: 100%;
    background-color: #1F1E1F;
    grid-template-areas:
        'header header header header header header'
        'main main main main right right'
        'footer footer footer footer right right'
        'bottomL bottomL bottomL bottomL bottomL bottomL';
    border-radius: 20px;
}

.WAW-grid-item {
    background-color: #1F1E1F;
    padding: 10px;
    text-align: left;
    color: white;
    display: flex;
}

.WAW-grid-container2-item1 {
    grid-area: header;
    padding: 35px 0px 35px 0px;
    background-color: #2A282A;
    border-radius: 10px;
    border: solid #726E72 1px;
    text-align: left;
    color: white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.WAW-grid-container2-item1-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: left;
}
.colored-text3 {
    background: linear-gradient(to right, #FFD72B, #FF8229);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
.colored-text2 {
    background: linear-gradient(to right, #FFD72B, #FF8229);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
.white-text2 {
    color: white;
}

.GOTHENBURG&WORKLOAD,
.WORKLOAD&GOTHENBURG {
    display: flex;
    align-items: center;
}


.WAW-grid-container2-item3 {
    grid-area: right;
    padding: 10px;
    background-color: #2A282A;
    border-radius: 10px;
    text-align: left;
}
.WAW-grid-container2-item3 {

}
.WAW-grid-container2-item4 {
}

.WAW-grid-container2-item4 {
    grid-area: footer;
    padding: 10px;
    background-color: #2A282A;
    text-align: left;
    border-radius: 10px;
    
}

    .grid-container > div {
        background-color: rgba(255, 255, 255, 0.8);
        padding: 20px ;
        font-size: 30px;
    }


.bigger-text{
    font-size: 25px;
}
.smaller-text {
    font-size: 15px;
}


@media screen and (max-width: 767.98px) {
    .SecondContainer {
        display: grid;
        grid-template-columns: 1fr;
    }
    .OpenPositionsDiv{

    }
    .WAW-grid-container2-item2,
    .WAW-grid-container2-item2-Internship {
        background-size: contain;
        height: 50px; /* Adjust the height as needed */
    }

    .WAW-grid-container2-item1-overlay{
        font-size: 10px;
    }
    .colored-text2 {
        font-size: 20px;
    }

    .WAW-grid-item {
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
    }

    .WAW-grid-container1 {
        width: 90%;
        padding: 0px;
        border-radius: 8px;
    }

    .WAW-grid-item-image{
    }

    .WAW-grid-container2 {
        display: grid;
        width: 75%;
        min-height: 800px;
        background-color: #1F1E1F;
        grid-template-areas:
            'header header header header  '
            'main main main main '
            'footer footer footer footer'
            'right right right right'
            'bottomL bottomL bottomL bottomL';
        gap: 20px;
        border-radius: 20px;
        padding: 20px;
        margin-top: 25px;
        min-height: 850px;
        max-height: 850px;
    }

    .WAW-grid-item-text {
        width: 100%;
        text-align: center;
    }

    .WAW-grid-item-image {
        margin-top: 25px;
        text-align: center;
    }
    .OpenPositionsDiv {
        width: 70%;
        margin-top: 25px;
        display: flex;
        justify-content: center; /* Center items horizontally */
        align-items: center; /* Center items vertically */
        text-align: center; /* Center text inside */
    }
    .JoinUs {
        width: 100%;

    }
}
@media screen and (min-width: 768px) and (max-width: 1023.98px) {
    .SecondContainer {
        display: grid;
        width: 75%;
        grid-template-columns: 1fr;
    }
    .FirstContainer {
        width: 75%;
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr;
    }
    .OpenPositionsDiv {
        width: 70%;
        display: flex;
        justify-content: center; /* Center items horizontally */
        align-items: center; /* Center items vertically */
        text-align: center; /* Center text inside */
    }

    .WAW-grid-container2-item2,
    .WAW-grid-container2-item2-Internship {
        background-size: contain;
        height: 50px;
    }
    .WAW-grid-container2-item1-overlay {
        font-size: 10px;
    }

    .colored-text2 {
        font-size: 20px;
    }

    .WAW-grid-item {
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
    }

    .WAW-grid-container1 {
        width: 100%;
        padding: 20px;
        border-radius: 8px;
    }

    .WAW-grid-item-image {
        text-align: right;
        width: 100%;
        text-align: center;
    }

    .WAW-grid-container2 {
        display: grid;
        width: 100%;
        min-height: 550px;
        background-color: #1F1E1F;
        grid-template-areas:
            'header header header header  '
            'main main main main '
            'footer footer right right'
            'footer footer right right '
            'bottomL bottomL bottomL bottomL';
        gap: 20px;
        border-radius: 20px;
        padding: 20px;
        margin-top: 10px;
    }

    .WAW-grid-item-text {
        width: 100%;
        text-align: left;
        padding: 5px;
    }


    .JoinUs {
        width: 100%;
    }

}

@media screen and (min-width: 1024px) and (max-width: 1439.98px) {
    .SecondContainer {
        display: grid;
        width: 65%;
        grid-template-columns: 1fr;
    }
    .FirstContainer {
        width: 65%;
        display: grid;
        justify-content: center;
    }
    .OpenPositionsDiv {
        width: 60%;
    }
    .WAW-grid-item {
        align-items: center;
        border-radius: 10px;
    }

    .WAW-grid-container1 {
        width: 100%;
        padding: 20px;
        border-radius: 8px;
    }

    .WAW-grid-item-image {
        text-align: right;
        width: 50%;
    }

    .WAW-grid-container2 {
        display: grid;
        width: 100%;
        background-color: #1F1E1F;
        grid-template-areas:
            'header header header header  '
            'main main main main '
            'footer footer right right'
            'footer footer right right '
            'bottomL bottomL bottomL bottomL';
        gap: 20px;
        border-radius: 20px;
        padding: 20px;
        margin-top: 50px;
        min-height: 575px;
        max-height: 575px;
    }

    .WAW-grid-item-text {
        width: 100%;
        text-align: left;
        padding: 5px;
    }
    .JoinUs {
        width: 100%;
    }
}
@media screen and (min-width: 1440px) and (max-width: 1600px) {
    .WAW-grid-container1 {
        width: 1110px;
        padding: 20px;
        margin-bottom: 50px;
    }

    .WAW-grid-container2 {
        display: grid;
        width: 95%;
        min-height: 500px;
        max-height: 500px;
        background-color: #1F1E1F;
        grid-template-areas:
            'header header header header header header'
            'main main main main right right'
            'footer footer footer footer right right'
            'bottomL bottomL bottomL bottomL bottomL bottomL';
        gap: 20px;
        border-radius: 8px;
        padding: 10px;
    }
    .WAW-grid-container2-item4 {
        min-height: 225px;
        max-height: 225px;
    }
    .ApplHereButton {
        min-height: 50px;
        max-height: 50px;
    }
    .FirstContainer {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        align-items: center;
        z-index: 2;
        justify-content: center;
    }

    .OpenPositionsDiv {
        width: 75%;
    }

    .SecondContainer {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        align-items: center;
        row-gap: 20px;
        z-index: 2;
        justify-content: center;
    }

    .WAW-grid-container2-item2,
    .WAW-grid-container2-item2-Internship {
        background-size: contain;
        height: 75px; /* Adjust the height as needed */
    }

}
    @media screen and (max-width: 1920px) and (min-width: 1601px) {
        .WAW-grid-container1 {
            width: 97%;
            padding: 20px;
            margin-bottom: 50px;
        }

        .WAW-grid-container2 {
            display: grid;
            width: 95%;
            background-color: #1F1E1F;
            grid-template-areas:
                'header header header header header header'
                'main main main main right right'
                'footer footer footer footer right right'
                'bottomL bottomL bottomL bottomL bottomL bottomL';
            gap: 20px;
            border-radius: 8px;
            padding: 10px;
            min-height: 525px;
            max-height: 525px;
        }

        .FirstContainer {
            width: 80%;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            justify-items: center;
            align-items: center;
            z-index: 2;
            justify-content: center;
        }

        .OpenPositionsDiv {
            width: 79%;
        }

        .SecondContainer {
            width: 80%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            justify-items: center;
            align-items: center;
            row-gap: 20px;
            z-index: 2;
            justify-content: center;
        }

        .WAW-grid-container2-item2,
        .WAW-grid-container2-item2-Internship {
            background-size: contain;
            height: 75px; /* Adjust the height as needed */
        }
        .WAW-grid-container2-item4 {
            min-height: 250px;
            max-height: 250px;
            top: 0;
        }
    }
@media screen and (min-width: 1921px) and (max-width: 1999px) {
    .WAW-grid-container1 {
        width: 97%;
        padding: 20px;
        margin-bottom: 50px;
    }

    .WAW-grid-container2 {
        display: grid;
        width: 95%;
        background-color: #1F1E1F;
        grid-template-areas:
            'header header header header header header'
            'main main main main right right'
            'footer footer footer footer right right'
            'bottomL bottomL bottomL bottomL bottomL bottomL';
        gap: 20px;
        border-radius: 8px;
        padding: 10px;
    }

    .FirstContainer {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        align-items: center;
        z-index: 2;
        justify-content: center;
    }


    .OpenPositionsDiv {
        width: 79%;
    }

    .SecondContainer {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        align-items: center;
        row-gap: 20px;
        z-index: 2;
        justify-content: center;
    }

    .WAW-grid-container2-item2,
    .WAW-grid-container2-item2-Internship {
        background-size: contain;
        height: 75px; /* Adjust the height as needed */
    }
}


@media screen and (min-width: 2000px) and (max-width: 3500px) {
    .BolderP {
        font-size: 24px;
        width: 80%;
    }

    .BolderH3 {
        font-size: 46px;
    }

    .JoinUs {
        width: 650px;
    }

    .WAW-grid-container1 {
        width: 97%;
        margin-top: 50px;
        padding: 20px;
        margin-bottom: 50px;
    }

    .WAW-grid-container2 {
        display: grid;
        width: 95%;
        min-height: 550px;
        max-height: 550px;
        background-color: #1F1E1F;
        grid-template-areas:
            'header header header header header header'
            'main main main main right right'
            'footer footer footer footer right right'
            'bottomL bottomL bottomL bottomL bottomL bottomL';
        gap: 20px;
        border-radius: 8px;
        padding: 10px;
    }

    .FirstContainer {
        width: 85%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        align-items: center;
        z-index: 2;
        justify-content: center;
    }


    .OpenPositionsDiv {
        width: 83%;
    }

    .SecondContainer {
        width: 85%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        align-items: center;
        row-gap: 20px;
        z-index: 2;
        justify-content: center;
        margin-bottom: 25px;
    }

    .WAW-grid-item-text {
        width: 100%;
        text-align: left;
        padding: 5px;
    }

    .colored-text2,
    .white-text2 {
        font-size: 30px;
    }

    .WAW-grid-container2-item3,
    .WAW-grid-container2-item4 {
        font-size: 20px;
    }


    .WAW-grid-container2-item3 {
        min-height: 250px;
        max-height: 350px;
        min-width: 350px;
        max-width: 350px;
    }
}
